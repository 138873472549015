import React from "react";
import { withStore } from "../store";
import { startSubscriptionFlow, mobileRedirect } from "./";
import { SignInModal, TrialProgressModal, UpgradeModal, RatingCheckModal } from "../components/Modals";
import ErrorPortal, { ErrorType } from "../components/Error/Error";
import User from "./User";

export default function withAuthorization(WrappedComponent) {
  class Authorize extends React.Component<any, any> {
    static displayName = `withModal(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    constructor(props) {
      super(props);
      this.state = {
        showSignInPrompt: false,
        showExpiredPrompt: false,
        showUpgradePrompt: false,
        showRatingCheckPrompt: false
      }
    };

    onClick = async () => {
      const { onClick, store: { country, onDesktop }, contentItem } = this.props
      onClick && onClick();

    console.log("User.authenticated() => ",User.authenticated())
    if(!User.authenticated()) {
        if(onDesktop) {
          this.setState({ showSignInPrompt: true });
        }
        else {
          mobileRedirect(process.env.REACT_APP_BANNER_ID);
        }
      }
      else if(await User.hasExpiredFreeTrial()) {
        console.log("Inside hasExpiredFreeTrial");
        this.setState({ showExpiredPrompt: true });
      }
      else if(User.hasFreeTrial() || User.hasActiveSubscription()) {
        if(country === "SG" || User.provider() === "BBC_ASTRO") {
          const authorized = await User.hasAuthorizedPackage(contentItem);
          if(authorized) {
            this.setState({ showRatingCheckPrompt: true });
          }
          else {
            this.setState({ showUpgradePrompt: true });
          }
        }
        else {
          this.setState({ showRatingCheckPrompt: true });
        }
      }
      else {
        if(country === "MY" && User.provider() !== "BBC_ASTRO") {
          const upgradeTypes = await User.getUpgradeTypes();
          console.log('startSubscriptionFlow')
          startSubscriptionFlow(upgradeTypes.trial, window.location.pathname);
        }
        else {
          console.log("set showUpgradePrompt");
          this.setState({ showUpgradePrompt: true });
        }
      }
    }

    render() {
      const { showSignInPrompt, showExpiredPrompt, showUpgradePrompt, showRatingCheckPrompt } = this.state;
      // Remove withStore added props
      const {
        store,
        updateSettings,
        updateMultipleSettings,
        signOut,
        setCountry,
        setDesktopFlag,
        playVideo,
        dismissVideo,
        signIn,
        contentItem,
        ...props
      } = this.props;

      return (
        <React.Fragment>
          <WrappedComponent {...props} onClick={this.onClick} />
          { showSignInPrompt && <SignInModal onClose={() => this.setState({ showSignInPrompt: false })} /> }
          { showExpiredPrompt && <TrialProgressModal onClose={() => this.setState({ showExpiredPrompt: false })} /> }
          { showUpgradePrompt && <UpgradeModal onClose={() => this.setState({ showUpgradePrompt: false })} /> }
          { showRatingCheckPrompt && <RatingCheckModal contentItem={contentItem} onClose={() => this.setState({ showRatingCheckPrompt: false })} /> }
        </React.Fragment>
      )
    }
  }

  return withStore(Authorize);
};
