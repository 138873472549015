import React from "react";
import { TrialProgressModal, EntitledModal } from ".";
import { getStorageItem } from "../../utilities";
import { withRouter } from "react-router";
import moment from "moment";

const Status: React.FunctionComponent<any> = ({ location: { pathname } }) => {
  const subType = getStorageItem("st", false, false, true);
  const previousReminder = getStorageItem("smr", false, false, true);
  const reminded = previousReminder !== null && moment(previousReminder).isSame(moment(), "day");
  const pathIgnored = [
    "/authentication/upgrade/web/callback",
    "/settings"
  ].includes(pathname);

  return (
    (pathIgnored) ?
      null :
      <React.Fragment>
        { !reminded && <TrialProgressModal /> }
        { subType &&  <EntitledModal type={subType} /> }
      </React.Fragment>
  );
};

export default withRouter(Status);
