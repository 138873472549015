import User from "./User";
import crypto2 from "crypto";
import querystring from "querystring";
import moment from "moment";
import withAuthorization from "./Authorize";
import Analytics from "./Analytics";
import _ from "lodash";

export const Constants = {
  Settings: "Settings",
  Live: "Live",
  Menu: "Menu",
  SignIn: "Sign In",
  More: "More",
  Downloads: "Downloads",
  TvGuide: "TV Guide",
  Channels: "Channels",
  Categories: "Categories",
  Cbeebies: "CBeebies",
  Today: "TODAY",
  Days: [ "SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  IOSString: /iPad|iPhone|iPod/,
  MobileWidth: 650,
  AutoplayCountdownTime: 10,
  ContinuityStartTime: 30,
  ContinuityEndTime: 40,
  PlayerAnalyticsPercentage: 0.9,
  HomePath: "/en",
  TvGuidePaths: ["cbeebies","bbc-earth","bbc-lifestyle"],
  LiveChannels: ["CBeebies","BBC Earth","BBC Lifestyle"],
  Prompts:["Signin", "Upgrade", "Expired"],
  TvGuideTarget: "/live/guide/",
  LiveTarget: "/live/",
  SubMenu: "app-body submenu",
  LiveSubMenu: "app-body submenu live",
  SignInUrl: `${process.env.REACT_APP_EVERGENT_URL}/user/signin`,
  SubscribeUrl: `${process.env.REACT_APP_EVERGENT_URL}/user/subscribenow`,
  UnifiSubscribeUrl: `${process.env.REACT_APP_UNIFI_URL}`,
  SignOutUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/authLogoutUser`,
  GetTokenUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/getToken`,
  TokenRefreshUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/refreshToken`,
  GetUpgradeTypesUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/getAvailableUpgradeTypes`,
  StarhubFallbackUrl: "https://www.bbcasia.com/bbcplayer",
  StarhubUpgradeUrl: "https://www.starhub.com/personal/tvplus/passes.html",
  SingtelUpgradeUrl: "https://www.singtel.com/personal/products-services/tv/packs",
  AstroUpgradeUrl:'https://api.whatsapp.com/send?phone=60395433838&text=Get%20BBC ',
  UnifiUpgradeUrl:'https://selfcare.unifi.com.my/login?ref=%2Fservice%2Ftvapps%2Fmy-entertainment',
  UnifiFallbackUrl: "http://bbcasia.com/bbcplayer",
  PlaybackAuthorizationUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/authorized`,
  UpdatePlaybackProgressUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/update`,
  CompletePlaybackProgressUrl: `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/complete`,
};

export const isLive = (item) => {
  const malaysiaTimeZoneOffset = 8 * 60 * 60 * 1000;
  const currentTime = new Date().getTime() + malaysiaTimeZoneOffset;
  const startTime = new Date(item.startTime_utc).getTime() + malaysiaTimeZoneOffset; 
  const endTime = startTime + (item.duration * 60 * 1000);
  return currentTime >= startTime && currentTime <= endTime;
}

export const getTime = (item) => {
  return moment(item.startTime_utc).format('HH:mm')
}

export const is_00_00_AM = (item, firstSchedule) => {
  const currentDate = new Date()
  const otherDate = new Date(item.startTime_utc); 
  if ( !(otherDate.getDate() === currentDate.getDate() &&
    otherDate.getMonth() === currentDate.getMonth() &&
    otherDate.getFullYear() === currentDate.getFullYear()) ) {
    return item.startTime_utc === firstSchedule.startTime_utc;
  }   
}

const Evergent = {
  clientId: "BBCPlayer_Web",
  scope: "offline_access,entitlements",
  deviceName: "pc",
  audience: "BBCplayer",
  locale: "en-US",
  device: getDeviceId()
};

const getWebsiteUrl = () => {
  const { protocol, host } = window.location;
  return process.env.REACT_APP_PUBLIC_URL_OVERRIDE || `${protocol}//${host}`;
};

const getEvergentCallbackUrl = () => `${getWebsiteUrl()}/authentication/upgrade/web/callback`;

export const getUrl = (url) => `${Constants.HomePath}/${url || ""}`.replace(/\/{2,}/g,"/").replace(/\/$/, "");
export const getFullUrl = (path) => `${getWebsiteUrl()}${path}`;
export const cleanUrl = (url) => url.replace(Constants.HomePath, "");

export const getCookie = (key) => {
  const cookie = document.cookie.split(";").filter(cookie => {
    const [k,] = cookie.trim().split("=");
    return k === key
  })[0];
  return cookie && cookie.trim().split("=")[1];
};

export const setCookie = (key, value, maxAge = 60*60*24*30*1000, path = "/") => {
  document.cookie=`${key}=${value};max-age=${maxAge};path=${path}`;
};

export const removeCookie = (key) => {
  document.cookie=`${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function setStorageItem(key, value, encoded = false, json = false, inSession = false) {
  const storage = inSession ? sessionStorage : localStorage;
  let val = value;
  if(json) val = JSON.stringify(val);
  if(encoded) val = window.btoa(val);
  storage.setItem(key, val);
};

export function getStorageItem(key, encoded = false, json = false, inSession = false): any {
  const storage = inSession ? sessionStorage : localStorage;
  let value = storage.getItem(key);
  if(value === null) return null;
  if(encoded) value = window.atob(value);
  if(json) {
    try {
      value = JSON.parse(value);
    } catch(err) {
      return null;
    }
  }
  return value;
};

export function epochToTime(epoch, duration) {
  let date = new Date(epoch).toString().split(" ");
  let startTimeArray = date[4].split(":");
  let startTime = startTimeArray[0] + ":" + startTimeArray[1];
  let endDate = new Date(epoch + (duration * 60 * 1000)).toString().split(" ");
  let endTimeArray = endDate[4].split(":");
  let endTime = endTimeArray[0] + ":" + endTimeArray[1];

  return startTime + " - " + endTime
};

export function removeStorageItem(key, inSession = false) {
  const storage = inSession ? sessionStorage : localStorage;
  storage.removeItem(key);
};

export const getContentItemMetadata = (contentItem, componentType) => {
  switch(componentType) {
    case "Tile":
      switch(contentItem.__typename) {
        case "Episode":
          return {
            header: contentItem.season.name,
            meta1: contentItem.name,
            meta2: getAvailability(contentItem)
          };
        case "Season":
          let episodeCount = contentItem.episodeCount;
          return {
            header: contentItem.name,
            meta1: `${contentItem.episodeCount} ${episodeCount > 1 ? "Episodes" : "Episode"}`,
            meta2: getAvailability(contentItem),
            url: getUrl(`/brand/${contentItem.series.path}/${contentItem.series.path}-s${contentItem.seasonNumber}`)
          }
        case "Series":
          let seasonCount = contentItem.seasonCount;
          return {
            header: contentItem.name,
            meta1: `${contentItem.seasonCount} ${seasonCount > 1 ? "Seasons" : "Season"}`,
            meta2: contentItem.channel,
            url: getUrl(`/brand/${contentItem.path}`)
          }
        default:
          return {};
      }

    case "Hero":
      switch(contentItem.__typename) {
        case "Episode":
          return {
            header: contentItem.season.name,
            subHeader: contentItem.name,
            description: contentItem.description
          };
        case "Season":
          let episodeCount = contentItem.episodeCount;
          return {
            header: contentItem.name,
            subHeader: `${contentItem.episodeCount} ${episodeCount > 1 ? "Episodes" : "Episode"}`,
            description: contentItem.description,
            episodesUrl: getUrl(`/brand/${contentItem.series.path}/${contentItem.series.path}-s${contentItem.seasonNumber}`)
          }
        case "Series":
          let seasonCount = contentItem.seasonCount;
          return {
            header: contentItem.name,
            subHeader: `${contentItem.seasonCount} ${seasonCount > 1 ? "Seasons" : "Season"}`,
            description: contentItem.description,
            episodesUrl: getUrl(`/brand/${contentItem.path}`)
          }
        default:
          return {};
      }
    default:
      return {};
  }
};

export const getQueryUrl = (url, query) => {
  return url ?
    query ?
      `${url}?${querystring.stringify(query)}` :
      url:
    undefined;
};

export const getQueryParams = (queryString) => {
  return querystring.parse(queryString.substring(1));
};

const generateAuthBrokenNonce = () => {
  const nonce = Math.random().toString(36).slice(2);
  setStorageItem("authbroker-authorize", nonce);
  return btoa(nonce);
};

export const validateAuthBrokerNonce = (encoded) => {
  const nonce = getStorageItem("authbroker-authorize");
  try {
    const decoded = atob(encoded);
    return nonce === decoded;
  } catch(err) {
    return false;
  }
};

const getTMUrl = (redirect) => {
  const redirectURI = `${getEvergentCallbackUrl()}?r=${redirect}#signin`;
  const state = generateAuthBrokenNonce();
  const responseType = "id_token";
  const operator_name = "tm";
  const country = "IN";

  const query = {
    scope: Evergent.scope,
    locale: Evergent.locale,
    client_id: Evergent.clientId,
    audience: Evergent.audience,
    device_name: Evergent.deviceName,
    device_serial_number: Evergent.device,
    country,
    redirectURI,
    responseType,
    state,
    operator_name
  };
  return getQueryUrl(Constants.SignInUrl, query);
};

const getStarhubUrl = (redirect) => {
  const redirectURI = `${getEvergentCallbackUrl()}?r=${redirect}#signin`;
  const state = generateAuthBrokenNonce();
  const responseType = "code";
  const operator_name = "starhub";
  const country = "SG";

  const query = {
    client_id: Evergent.clientId,
    deviceName: Evergent.deviceName,
    scope: Evergent.scope,
    locale: Evergent.locale,
    audience: Evergent.audience,
    deviceId: Evergent.device,
    deviceSerial: Evergent.device,
    country,
    redirectURI,
    operator_name,
    responseType,
    state,
  };
  return getQueryUrl(Constants.SignInUrl, query);
};
const getAstroUrl = (redirect) => {
  const redirectURI = `${getEvergentCallbackUrl()}?r=${redirect}#signin`; 
  const state = generateAuthBrokenNonce();
  const responseType = "id_token";
  const operator_name = "astro";
  const country = "IN";

  const query = {
    scope: Evergent.scope,
    locale: Evergent.locale,
    client_id: Evergent.clientId,
    audience: Evergent.audience,
    device_name: Evergent.deviceName,
    device_serial_number: Evergent.device,
    country,
    redirectURI,
    responseType,
    state,
    operator_name
  };
  return getQueryUrl(Constants.SignInUrl, query);
};

const getSingtelUrl = (redirect) => {
  const redirectURI = `${getEvergentCallbackUrl()}?r=${redirect}#signin`;
  const state = generateAuthBrokenNonce();
  const responseType = "code";
  const operator_name = "singtel";
  const country = "SG";

  const query = {
    client_id: Evergent.clientId,
    deviceName: Evergent.deviceName,
    scope: Evergent.scope,
    locale: Evergent.locale,
    audience: Evergent.audience,
    deviceId: Evergent.device,
    deviceSerial: Evergent.device,
    country,
    redirectURI,
    operator_name,
    responseType,
    state,
  };
  return getQueryUrl(Constants.SignInUrl, query);
};

export const getSignInUrl = (country, redirect, operator) => {
  if (country === "SG") {
    return operator === "singtel" ?
      getSingtelUrl(redirect) :
      getStarhubUrl(redirect);
  } else {
    return operator === "astro" ?
    getAstroUrl(redirect) :
    getTMUrl(redirect);
  }
};

export const AuthorizeLiveplayer = async (
  isAbroad, enableAbroadBanner, setshowPrompt, country, channelname, channel, playLiveVideo) => {
  if (window.innerWidth <= Constants.MobileWidth) {
    mobileRedirect(process.env.REACT_APP_BANNER_ID);
  } else {
    if(isAbroad){
      enableAbroadBanner();
      window.scrollTo(0, 0);
    }
    else if(!User.authenticated()) {
      setshowPrompt(Constants.Prompts[0]);
    }
    else if(await User.hasExpiredFreeTrial()) {
      setshowPrompt(Constants.Prompts[2]);
    }
    else if(User.hasFreeTrial() || User.hasActiveSubscription()) {
      if(country === "SG" || User.provider() === "BBC_ASTRO") {
        const authorized = await User.hasAuthorizedPackage({channel: channelname});
        if(authorized) {
          channel.openLivePlayer = true
          if (channel.callSchedule ){
            channel = await callSchedule(channel);
          }
          playLiveVideo(channel)
        }
        else {
          setshowPrompt(Constants.Prompts[1]);
        }
      }
      else {
        channel.openLivePlayer = true
        if (channel.callSchedule ){
          channel = await callSchedule(channel);
        }
        playLiveVideo(channel)
      }
    }
    else {
      if(country === "MY" && User.provider() !== "BBC_ASTRO") {
        const upgradeTypes = await User.getUpgradeTypes();
        startSubscriptionFlow(upgradeTypes.trial, window.location.pathname);
      }
      else {
        setshowPrompt(Constants.Prompts[1]);
      } 
    }
  }
};

export const callSchedule = async( input ) => {
  const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/channels/schedule/${getDateString()}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "x-platform": "WEB"
      }
    } );
    const schedule = await response.json();
    const channel = schedule.find(ele => ele.id === input.channelId || 
      (ele.title && input.title && ele.title.toUpperCase() === input.title.toUpperCase()) );
    input.id = channel.videoIdHD;
    input.thumbnail = channel.logo;
    input.schedules = channel.schedules;
    input.callSchedule = false;
    return input
  } catch (error) {
    console.log(error)
    input.error = true;
    return input
  }
}

export const getDateString = () => { 
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const timezoneOffsetMinutes = date.getTimezoneOffset();
  const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
  const timezoneOffsetSign = timezoneOffsetMinutes > 0 ? '-' : '+';
  const timezoneOffsetFormatted = `${timezoneOffsetSign}${String(timezoneOffsetHours).padStart(2, '0')}:${String(Math.abs(timezoneOffsetMinutes % 60)).padStart(2, '0')}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetFormatted}`;
 };

export const getProviderInfo = (country) => {
  return {
    provider: (country === "MY" && "unifi") || (country === "SG" && "StarHub") || undefined,
    providerId: (country === "MY" && "unifi") || (country === "SG" && "Hub ID") || undefined,
    fallbackUrl: (country === "MY" && Constants.UnifiFallbackUrl) || (country === "SG" && Constants.StarhubFallbackUrl) || undefined,
    image: (country === "MY" && null) || (country === "SG" && null) || undefined,
    startSignIn: (operatorSelected) => {
      const pathname = window.location.pathname === "/authentication/upgrade/web/callback" ?
        "/" :
        window.location.pathname;
      const url = getSignInUrl(country, pathname, operatorSelected);
      setStorageItem("operator", operatorSelected, true);
      if(url) window.location.href = url
    }
  }
};

/* eslint-disable no-mixed-operators */
function getDeviceId() {
  let guid = getStorageItem("device_id");
  if(!guid) {
    guid = ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
    setStorageItem("device_id", guid!);
  }
  return guid;
};

export const startSubscriptionFlow = (trial, redirect) => {
  const redirectURI = `${getEvergentCallbackUrl()}?r=${redirect}#subscribe`;
  const sub = User.decodedToken().sub;
  const exp = Date.now() + (356 * 24 * 60 * 60 * 1000);

  const query = {
    client_id: Evergent.clientId,
    redirectURI,
    trial,
    sub,
    exp,
  };
  const key = crypto2
    .createHmac('sha256', 'eVergEntH@@&M@LS')
    .update(querystring.stringify(query))
    .digest('hex');

  window.location.href = getQueryUrl(Constants.SubscribeUrl, { ...query, key });
};

export const getAvailability = (contentItem) => {
  if(!["Episode", "Season"].includes(contentItem.__typename))
    return "";

  const availability: string[] = [];
  if(contentItem.end)
    availability.push(`Available for ${moment(contentItem.end).diff(moment(), "days")} days`);
  if(contentItem.channel)
    availability.push(contentItem.channel);

  return availability.join(" | ");
};

export const evergentRequest = (url, body, responseKey) => {
  const headers = { "content-type": "application/json" };
  if(User.authenticated())
    headers["Authorization"] = User.authHeader();

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  }).then(async response => {
    try {
      const data = await response.json();
      const message = data[responseKey];
      const status = message.responseMessage || message.message;

      if(status === "SUCCESS") {
        return message;
      }
      throw new Error("Evergent error");
    } catch(err) {
      console.log(err);
      return undefined;
    }
  });
};

export const lockScroll = () => {
  const body = document.querySelector("body");
  body!.classList.add("scroll-lock");
};

export const unlockScroll = () => {
  const body = document.querySelector("body");
  body!.classList.remove("scroll-lock");
};

export const addWindowChangeListener = (fn) => {
  window.addEventListener("resize", fn);
  window.addEventListener("orientationchange", fn);
};

export const removeWindowChangeListener = (fn) => {
  window.removeEventListener("resize", fn);
  window.removeEventListener("orientationchange", fn);
};

export const getCertifications = async () => {
  let certifications = getStorageItem("ratings", true, true);
  if(certifications) {
    return certifications;
  }
  else {
    let res = await fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/certifications`);
    if(res.ok) {
      try {
        let data = await res.json();
        certifications = _.sortBy(data.data.certifications.nodes, ["age"]);
        setStorageItem("ratings", certifications, true, true);
        return certifications;
      } catch(err) {
        return [];
      }
    }
    return [];
  }
};

export const Browser = {
  isChrome: () => {
    const userAgent = window.navigator.userAgent;
    return /Chrome\/[0-9.]+/g.test(userAgent) && !/(Chromium|Edge)/gi.test(userAgent);
  },
  isSafari: () => {
    const userAgent = window.navigator.userAgent;
    return /Safari\/[0-9.]+/gi.test(userAgent) && !/(Chrome|Chromium)/gi.test(userAgent);
  },
  isFirefox: () => {
    const userAgent = window.navigator.userAgent;
    return /Firefox\/[0-9.]+/gi.test(userAgent) && !/Seamonkey/gi.test(userAgent);
  },
  isEdge: () => {
    const userAgent = window.navigator.userAgent;
    return /(Edg|Edge)\/[0-9.]+/gi.test(userAgent);
  },
  isIE: () => {
    const userAgent = window.navigator.userAgent;
    return /(MSIE [0-9.]+)|(Trident\/[0-9.]+)/gi.test(userAgent);
  }
};

export const analyticsSeriesName = (seriesPath) => seriesPath.replace(/\//g,"").replace(/-/g,"_");

export const getAnalyticsPage = () => {
  const components = ["player"];
  const [, , section, subSection1, subSection2] = window.location.pathname.split("/");
  let cleanedSection = section;

  switch(section) {
    case "category":
    case "categories":
    case "channel":
    case "channels":
      cleanedSection = section
        .replace(/^channel$/, "channels")
        .replace(/^category$/, "categories");
      components.push(cleanedSection);
      components.push(subSection1);
      break;

    case "brand":
      components.push(analyticsSeriesName(subSection1));
      if(subSection2)
        components.push(subSection2.replace(/[\D]+/g,"season_"));
      break;

    case "viewall":
      components.push(subSection1.replace(/-/g,"_"));
      break;

    case "about":
    case "help-and-support":
    case "terms-and-condititions":
      cleanedSection = section
      .replace("terms-and-condititions", "TnC")
      .replace("-","_");

      components.push("settings");
      components.push(cleanedSection);
      break;

    case "settings":
      let settingsSection = querystring.parse(window.location.search)["?page"];
      switch(settingsSection) {
        case "pin":
        case "av":
          components.push(`settings_${settingsSection}`);
          break;
        case "sign-out":
          components.push(section);
          components.push("sign_out");
          break;
        default:
          break;
      }
      break;

    default:
      components.push("homepage");
  }
  components.push("page");
  return components.join(".");
};

export const mobileRedirect = (banner_link_id='') => {
  window.history.pushState({}, window.location.href);
  window.location.href = `//${process.env.REACT_APP_BRANCH_URL}?_branch_view_id=${banner_link_id}`;
};

export { User, withAuthorization, Analytics };

